.content {
  max-width: 994px;
  margin: 0 auto;
}

.form {
  background: #ffffff;
  border: 1px solid #ddd;
  padding: 35px 15px 35px 15px;
  margin: 27px 10px 5px 10px;
}

.box-wrap {
  margin-top: 27px;
  text-align: center;
  vertical-align: middle;
}

.App-logo {
  margin: auto;
}

.title {
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  margin: 27px 0px;
}

.text-center {
  text-align: center;
}

.env_red {
  color: #e60012;
}

.form_group {
  margin-bottom: 15px;
}

.form_control {
  width: 100%;
  height: 36px;
  padding: 6px 10px 6px 10px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #555;
  vertical-align: middle;
  outline: none;
  border: 1px solid #009fb8;
  border-radius: 5px;
  box-sizing: border-box;
}

.btn {
  width: 100px;
}

.text_alert {
  display: inline;
  color: #bf1650;
}

.text_alert::before {
  content: '⚠ ';
}

.text_danger {
  color: #a94442;
}

.text_info {
  color: #31708f;
}

@media (min-width: 768px) {
  .control_label {
    text-align: right;
  }
  .col-md-offset-2 {
    margin-left: 31.9%;
  }
}

input[type='submit']:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type='submit'] {
  -webkit-appearance: none; /* Safari 和 Chrome */
}
