.modal-body {
  padding: 40px 20px 40px 20px;
}

.modal_group {
  margin-bottom: 15px;
}

.help_block {
  display: inline-block;
  vertical-align: top; // 超重要
}

.buttonContent {
  border-top: 1px solid #dee2e6;
  padding: 15px;
}

.btnItem {
  width: 125px;
  margin: 0px 5px;
}

.print {
  overflow: hidden;
  height: 0;
}

@media (min-width: 992px) {
  .modal_label {
    display: inline-block;
    text-align: right;
  }
}
