/* invoice css */
.contain {
  width: 80mm;
  margin: 5px auto;
  padding: 10px 10px;
  background-color: #fff;
  font-size: 16px;
  border: 1px solid black;
}

.stone {
  font-size: 30px;
  text-align: center;
  color: #045c1a;
}

.notice {
  font-size: 36px;
  text-align: center;
}

.invoiceNo {
  margin: 15px;
}

.warn {
  color: #dc143c;
}

.itemContent {
  margin-bottom: 5px;
}

/* shared css */
.flex-block {
  display: flex;
  justify-content: space-between;
}

.p-center {
  text-align: center;
}
