.alert-content {
  min-width: 450px;
  min-height: 110px;
  border: 1px solid #fdfdfd;
  background-color: #fdfdfd;
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 10px 2.5px rgba(0, 0, 0, 0.3);
  padding: 14px;
}

.message-content {
  // background-color: yellow;
  min-height: 8vh;
}

.button-content {
  // background-color: yellow;
  text-align: right;
}

.alert-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 0.25rem;
}
