/* initial css */
@media print {
  p {
    font-size: 16px;
    margin-bottom: 0px !important;
    margin: 0mm;
    padding: 0mm;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  th {
    text-align: center;
    font-weight: normal;
  }
}

/* invoice css */
.container {
  background-color: #fff;
}

.header {
  margin: 1px 2px;
}

.title {
  font-size: 20px;
  // padding-top: 15px;
}

.title-date {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.invoice {
  border-spacing: 0mm;
  clear: both;
  width: 100%;
  height: 360mm;
}

.colWidth {
  width: 24.5mm;
}

.invoice_line {
  height: 6mm;
}

.food_line {
  height: 12mm;
}

tr.noBorder td {
  border-top: 0mm;
  border-bottom: 0mm;
  vertical-align: top;
}

/* shared css */
.flex-block {
  display: flex;
  justify-content: space-between;
}

.p-center {
  text-align: center;
}

.p-left {
  text-align: left;
}

.p-right {
  text-align: right;
}

@page {
  size: A4 portrait;
}
